
export const CopyIcon = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_344_1419)">
            <path d="M14.24 2.46811H10.346C8.582 2.46811 7.184 2.46811 6.091 2.61611C4.965 2.76811 4.054 3.08811 3.336 3.80911C2.617 4.53011 2.298 5.44511 2.147 6.57511C2 7.67311 2 9.07611 2 10.8471V16.6851C2 18.1931 2.92 19.4851 4.227 20.0271C4.16 19.1171 4.16 17.8421 4.16 16.7801V11.7701C4.16 10.4891 4.16 9.38411 4.278 8.50011C4.405 7.55211 4.691 6.64411 5.425 5.90711C6.159 5.17011 7.064 4.88311 8.008 4.75511C8.888 4.63711 9.988 4.63711 11.265 4.63711H14.335C15.611 4.63711 16.709 4.63711 17.59 4.75511C17.326 4.08148 16.8652 3.503 16.2676 3.09507C15.6701 2.68713 14.9635 2.46865 14.24 2.46811Z" fill="white" />
            <path d="M5.6001 11.8651C5.6001 9.13912 5.6001 7.77612 6.4441 6.92912C7.2871 6.08212 8.6441 6.08212 11.3601 6.08212H14.2401C16.9551 6.08212 18.3131 6.08212 19.1571 6.92912C20.0001 7.77612 20.0001 9.13912 20.0001 11.8651V16.6851C20.0001 19.4111 20.0001 20.7741 19.1571 21.6211C18.3131 22.4681 16.9551 22.4681 14.2401 22.4681H11.3601C8.6451 22.4681 7.2871 22.4681 6.4441 21.6211C5.6001 20.7741 5.6001 19.4111 5.6001 16.6851V11.8651Z" fill="white" />
        </g>
        <defs>
            <filter id="filter0_d_344_1419" x="0.468083" y="0.170234" width="22.5958" height="24.5958" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.765958" />
                <feGaussianBlur stdDeviation="1.14894" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_344_1419" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_344_1419" result="shape" />
            </filter>
        </defs>
    </svg>
)