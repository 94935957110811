import DotsLoader from '../DotsLoader/DotsLoader';
import "./Button.scss";

const Button = (props) => {
    const { loading, fluid, children, text, className, ...rest } = props;
    return (
        <button type="button" {...rest} className={`custom_btn ${fluid ? "w-100" : ""} ${className || ""}`}>
            {
                loading ?
                    <DotsLoader />
                    :
                    text || children
            }
        </button>
    )
}

export default Button
