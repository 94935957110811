import axios from "axios";
//@ts-ignore
import * as CryptoJS from "crypto-js";
import { getKey } from "../Utils/index";

const IS_ENCRYPTION = process.env.REACT_APP_IS_ENCRYPTION === "true";
const BASE_URL = "";
const key = getKey(50);
const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config?.headers?.noAuth === "true") {
      config.headers["api-access-token"] = `${token}`;
    } else if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// function handleError(error) {
//   if (error?.response?.status === 403 || error?.response?.status === 401) {
//     // toasts.error("Please re-login, last login session expired.");
//     localStorage.clear()
//   } else {
//     // error?.data?.message && toasts.error(error.data.message);
//     // error?.response?.data?.message && toasts.error(error.response.data.message);
//   }
// }
export function encryption(payload) {
  try {
    if (payload != undefined || payload != null) {
      const ciphertext = CryptoJS.AES.encrypt(payload, key).toString();

      return ciphertext;
    }
  } catch (error) {
    return error;
  }
}

// //decoding
export function decryption(payload) {
  try {
    if (payload) {
      const decryptedText = CryptoJS.AES.decrypt(payload, key);
      const decryptData = decryptedText.toString(CryptoJS.enc.Utf8);

      return decryptData;
    }
  } catch (error) {
    console.log(error);
  }
}
export const encryptionFilter = (data) => {
  if (!data?.entries && data?.entries === undefined) {
    const encD = IS_ENCRYPTION ? encryption(JSON.stringify(data)) : data;
    return IS_ENCRYPTION ? { reqData: encD } : data;
  } else {
    return data;
  }
};
export const decryptionFilter = (data) => {
  if (data && data?.resData && typeof data?.resData == "string") {
    const decD = IS_ENCRYPTION ? JSON.parse(decryption(data?.resData)) : data;
    return IS_ENCRYPTION ? decD : data;
  } else {
    return data;
  }
};
function handleSuccess(res) {
  if (res?.status === 200 || res?.status === 201) {
    // res?.message && toasts?.success(res?.message);
    // res?.data?.message && toasts?.success(res?.data?.message);
  }
  if (res.status === 403 || res.status === 400) {
    // res.message && toasts.warning(res.message);
  }
}

async function apiCallGet(
  url,
  header,
  toastOn = false
  // loader = false
) {
  try {
    const response = await axiosApi.get(url, header);
    const decryptData = decryptionFilter(response?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }

    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      // toasts.error('Please re-login, last login session expired.')
      localStorage.removeItem("token");
      // window.location.reload()
    }
    if (
      (decryptData && decryptData?.message) ||
      decryptData?.message === "Network Error"
    ) {
      // toastOn && toasts.error(decryptData?.message)
    }
    return decryptData;
  }
}

async function apiCallPost(
  url,
  data,
  toastOn = true
  // loader = false
) {
  //   loader && storeInstance.dispatch(setLoader(true));
  const dataFiltered = encryptionFilter(data);

  try {
    const resp = await axiosApi.post(url, dataFiltered, {});
    const decryptData = decryptionFilter(resp?.data);
    console.log("🚀 ~ decryptData:", decryptData);

    if (toastOn === true) {
      handleSuccess(decryptData);
    }

    // loader && storeInstance.dispatch(setLoader(false));
    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    // loader && storeInstance.dispatch(setLoader(false));

    if (error?.response?.status && error?.response?.status === 401) {
      // toasts.error('Please re-login, last login session expired.')
      localStorage.removeItem("token");
      window.location.reload();
    }

    if (decryptData?.message || decryptData?.message === "Network Error") {
      // toastOn && toasts.error(decryptData?.message)
    }

    return decryptData;
  }
}

// async function apiCallPut(
//     { url,
//         data,
//         toastOn,
//         loader = false }: ApiRequest<T>
// ): Promise<T> {
//     let dataFiltered = data;
//     //   loader && storeInstance.dispatch(setLoader(true));
//     const resp = await axiosApi
//         .put(url, dataFiltered)
//         .then(async (res) => {
//             let decryptData = res?.data

//             if (toastOn === true) {
//                 handleSuccess(decryptData);
//             }
//             //   loader && storeInstance.dispatch(setLoader(false));
//             return decryptData;
//         })
//         .catch((error) => {
//             //   loader && storeInstance.dispatch(setLoader(false));
//             let decryptData = error?.response?.data
//             if (error?.response?.status && error?.response?.status === 401) {
//                 // toasts.error("Please re-login, last login session expired.");
//                 localStorage.clear();
//             }
//             if (
//                 (decryptData && decryptData?.message) ||
//                 decryptData?.message === "Network Error"
//             ) {
//                 // toasts.error(decryptData?.message);
//             }
//             return decryptData;
//         });

//     return resp;
// };

async function apiCallPatch(url, data, toastOn, loader) {
  let dataFiltered = data;
  //   loader && storeInstance.dispatch(setLoader(true));
  const resp = await axiosApi
    .patch(url, dataFiltered)
    .then(async (res) => {
      let decryptData = res?.data;

      if (toastOn === true) {
        handleSuccess(decryptData);
      }
      //   loader && storeInstance.dispatch(setLoader(false));
      return decryptData;
    })
    .catch((error) => {
      //   loader && storeInstance.dispatch(setLoader(false));
      let decryptData = error?.response?.data;
      if (error?.response?.status && error?.response?.status === 401) {
        // toasts.error("Please re-login, last login session expired.");
        localStorage.clear();
      }
      if (
        (decryptData && decryptData?.message) ||
        decryptData?.message === "Network Error"
      ) {
        // toasts.error(decryptData?.message);
      }
      return decryptData;
    });

  return resp;
}
async function apiCallDelete(
  url,
  header,
  toastOn = false
  // loader = false
) {
  try {
    const response = await axiosApi.delete(url, header);
    const decryptData = decryptionFilter(response?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }

    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      // toasts.error('Please re-login, last login session expired.')
      localStorage.removeItem("token");
      // window.location.reload()
    }
    if (
      (decryptData && decryptData?.message) ||
      decryptData?.message === "Network Error"
    ) {
      // toastOn && toasts.error(decryptData?.message)
    }
    return decryptData;
  }
}
// return new Promise((resolve, reject) => {
//     axiosApi
//         .delete(url)
//         .then((res) => {
//             if (toastOn === true) {
//                 handleSuccess(res);
//             }
//             resolve(res.data);
//         })
//         .catch((error) => {
//             handleError(error);
//             reject(error);
//         });
// });
// }
// const apiCallDelete = (url, toastOn) => {
//     return new Promise((resolve, reject) => {
//         axiosApi
//             .delete(url)
//             .then((res) => {
//                 if (toastOn === true) {
//                     handleSuccess(res);
//                 }
//                 resolve(res.data);
//             })
//             .catch((error) => {
//                 handleError(error);
//                 reject(error);
//             });
//     });
// };

export {
  apiCallGet,
  apiCallPost,
  // apiCallPut,
  apiCallPatch,
  apiCallDelete,
};
