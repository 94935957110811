import "./DotsLoader.scss";

const DotsLoader = () => {
    return (
        <div className="dots_loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default DotsLoader
