import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./index.scss";
// import { verifyUuid } from "../../Utils";
import * as MetaMaskSDK from "@metamask/sdk";
import { useNavigate, useParams } from "react-router-dom";
import { apiCallPost } from "../../Client/axios";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";
import { CopyIcon } from "../../Assets/icons";
const ENV_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export default function Home() {
  const navigate = useNavigate();
  const [sdk, setSdk] = useState(null);
  const [chainId, setChainId] = useState(null);

  const [accounts, setAccounts] = useState(null);
  const { connectionId } = useParams();
  const [loader, setloader] = useState(false);
  const [loading, setloading] = useState(false);

  const [provider, setprovider] = useState(null);
  useEffect(() => {
    if (connectionId) {
      (async () => {
        const url = process.env.REACT_APP_BACKEND_URL + "/validate-token";
        const body = {
          token: connectionId,
        };

        const fetchRes = await apiCallPost(url, body);
        if (fetchRes.error) {
          navigate("/error");
        }
      })();
    }
  }, [connectionId]);

  useEffect(() => {
    if (accounts) {
      (async () => {
        setloader(true);
        const url = process.env.REACT_APP_BACKEND_URL + "/address";
        const body = {
          symbol: "ETH",
          address: accounts,
          token: connectionId,
        };
        const fetchRes = await apiCallPost(url, body);
        console.log("fetchRes :: ", fetchRes);
        if (fetchRes?.status === 200) {
          window.location.href = process.env.REACT_APP_BOT;
        } else {
          console.log("Error .............");
          navigate("/error");
        }
        setloader(false);
      })();
    }
  }, [accounts, chainId]);
  const getSdk = () => {
    try {
      const sdk_ = new MetaMaskSDK.MetaMaskSDK({
        dappMetadata: {
          name: "Ancrypto",
          url: window.location.host,
        },
        logging: {
          sdk: false,
        },
      });
      setSdk(sdk_);
    } catch (error) {
      console.error(error);
    }
  };
  const clearCache = () => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie =
        c.trim().split("=")[0] +
        "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };

  useEffect(() => {
    clearCache();
    getSdk();
  }, []);

  async function connect() {
    setloading(true);
    try {
      const provider_ = await sdk?.getProvider();
      if (provider_) {
        setprovider(provider_);
        setloading(false);
        const accounts_ = await provider_.request({
          method: "eth_requestAccounts",
        });

        const currentChainId = await provider_.request({
          method: "eth_chainId",
        });

        setChainId(currentChainId);
        setAccounts(accounts_[0]);
      } else {
        getSdk();
        setloading(false);
      }
    } catch (error) {
      if (error.code === -32002) {
        toast.success(
          "Request for Wallet is already pending . Please open Metamask",
          { className: "warning" }
        );
      }
      setloading(false);

      console.log("request accounts ERR", error);
    }
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      toast.error("Please connect to MetaMask.");
      setAccounts(null);
    } else if (accounts[0] !== accounts) {
      setAccounts(accounts[0]);
    }
  }

  function handleDisconnect(error) {
    toast.error("MetaMask disconnected");
    setAccounts(null);
  }
  const handleButtonClick = () => {
    window.location.href = process.env.REACT_APP_BOT;
    setTimeout(() => {
      window.close();
    }, 2000);
  };
  // async function addNetwork() {
  //   try {
  //     await provider.request({
  //       method: "wallet_addEthereumChain",
  //       params: [
  //         {
  //           chainId: ENV_CHAIN_ID,
  //           chainName: process.env.REACT_APP_CHAIN_NAME,
  //           blockExplorerUrls: [process.env.REACT_APP_EXPLORER_URL],
  //           nativeCurrency: {
  //             symbol: process.env.REACT_APP_CHAIN_SYMBOL,
  //             decimals: Number(process.env.REACT_APP_CHAIN_DECIMALS ?? 18),
  //           },
  //           rpcUrls: [process.env.REACT_APP_RPC_URL],
  //         },
  //       ],
  //     });
  //     toast.success(
  //       process.env.REACT_APP_CHAIN_NAME + " network added successfully"
  //     );
  //     const currentChainId = await provider.request({
  //       method: "eth_chainId",
  //     });
  //     setChainId(currentChainId);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  return (
    <div className="btn-container">
      <div className="in">
        <h2 className="mb-4">Ancrypto</h2>
        <center>
          {accounts ? (
            <div className="steps_form_content">
              {loader && <Loader />}
              <p className="content mb-4">
                You have successfully connected your MetaMask wallet.
              </p>
              {/* <p className="content mb-4">Current Chain ID: {chainId}</p> */}
              <div className="copy_area">
                <p>
                  {accounts.slice(0, 4)}
                  ...
                  {accounts.slice(accounts.length - 4)}
                </p>
                <Button>
                  <CopyIcon />
                </Button>
              </div>
              <>
                <Button fluid className="mt-4 p-0" onClick={handleButtonClick}>
                  Click here to redirect to the game.
                </Button>
              </>
            </div>
          ) : (
            <div className="steps_form_content">
              <p className="content mb-4">
                Click the button below to connect to MetaMask.
              </p>
              <div className="content mb-4">
                <b>Note:</b> If you're using a mobile device and aren't
                automatically getting popup to connect MetaMask, please clear
                your browser cache first and then try to connect.
              </div>
              <div className="action_btn justify-content-center">
                <Button loading={loading} onClick={connect}>
                  Connect Wallet
                </Button>
              </div>
            </div>
          )}
        </center>
      </div>
    </div>
  );
}
